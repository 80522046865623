import React from 'react'
import 'gestalt/dist/gestalt.css'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Lottie from 'lottie-react'
import nerd from './nerd.json'
import {
  Box,
  Button,
  IconButton,
  Checkbox,
  CompositeZIndex,
  Fieldset,
  FixedZIndex,
  Flex,
  Layer,
  RadioButton,
  Sheet,
  Text,
  TextField,
} from 'gestalt'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { render } from 'react-dom'

export default function AccessibilityExample(data) {
  console.log(data)
  const SheetWithAccessibilityLabels = ({ onDismiss, bio }) => {
    return (
      <Sheet
        accessibilityDismissButtonLabel="Close audience creation sheet"
        accessibilitySheetLabel="Audience list creation for new campaign"
        heading="About Megan"
        onDismiss={onDismiss}
        size="md"
      >
        <Flex direction="column">
          <p style={{ fontSize: '22px' }}>
            I&apos;m Megan, a product leader who loves creating innovative
            products that make a difference in people&apos;s lives. I thrive in
            fast paced, high growth environments, and I have experience leading
            product development teams to successfully launch new product
            verticals, optimize integrations, and drive meaningful growth in
            both revenue and user acquisition. I love to dive into the details
            and work closely with cross-functional teams to deliver
            comprehensive strategies, establish meaningful metrics, and drive
            impactful change that solve real-world problems. Let&apos;s connect!
          </p>
          <div
            style={{
              float: 'right',
              position: 'relative',
              overflow: 'hidden',
              right: '35px',
            }}
          >
            <Lottie
              animationData={nerd}
              loop={false}
              style={{ width: '1600px', marginLeft: -700, overflow: 'hidden' }}
            />
          </div>
        </Flex>
      </Sheet>
    )
  }

  const [shouldShow, setShouldShow] = React.useState(false)
  const HEADER_ZINDEX = new FixedZIndex(10)
  const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX])

  return (
    <React.Fragment>
      <IconButton icon="info-circle" onClick={() => setShouldShow(true)} />
      {shouldShow && (
        <Layer zIndex={sheetZIndex}>
          <SheetWithAccessibilityLabels
            onDismiss={() => setShouldShow(false)}
          />
        </Layer>
      )}
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  {
    allContentfulPerson {
      nodes {
        bio {
          bio
        }
      }
    }
  }
`
