import React from 'react'
import { Link } from 'gatsby'
import Lottie from 'lottie-react'
import Smile from './smile.json'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
    <StaticImage placeholder="blurred" style={{width: "50px", marginRight:"5px"}} alt={"Megan Bubley Headshot"} src={"https://i.ibb.co/GPJt1q3/meg-avatar.png"} />
    {/* <img style={{width: "50px", marginRight:"5px"}}src="https://i.ibb.co/GPJt1q3/meg-avatar.png"/> */}
      <span className={styles.navigationItem}>Megan Bubley</span>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/blog/" activeClassName="active">
          Musings
        </Link>
      </li>
    </ul>
  </nav>
)

export default Navigation
