import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button, Icon } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import AccessibilityExample from './sheet';

import * as styles from './hero.module.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Hero = ({ image, title, content }) => (
  <div className={styles.hero}>
    {image && (
      <GatsbyImage className={styles.image} alt={title} image={image} />
    )}
    <div className={styles.details}>
      <h1 className={styles.title}>{title}<AccessibilityExample/></h1>
      {/* <h1 className={styles.title}>{title}</h1> */}
      {content && <p className={styles.content}>{content} @ <a href="https://atmosphere.tv" alt="Atmosphere TV"><img src="https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_c70aa14a6a1612f789500b4db49ee785/atmosphere-tv.png"/></a></p>}
      <Button role="link" iconEnd="speech" className="button" color="white" href="https://www.linkedin.com/messaging/thread/new?recipients=List(urn%3Ali%3Afsd_profile%3AACoAAA90FdYBcn6RLFk7JFKsMdq7F-R-QbWQaQ4)&composeOptionType=CONNECTION_MESSAGE&controlUrn=compose_message_button&referringModuleKey=NON_SELF_PROFILE_VIEW&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BuBVaqJjtTc2S1Dm3MYzetA%3D%3D" text="Message me on LinkedIn" />
    </div>
  </div>
)

export default Hero
