import React from 'react'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <Container as="footer">
    <div className={styles.container}>
      Built with <a href="https://contentful.com/">Contentful</a>,{' '}
      <a href="https://gatsbyjs.com">Gatsby</a>{' '}and ❤️ by <a href="https://github.com/ckellgren">Corey Kellgren</a>
    </div>
  </Container>
)

export default Footer
